._1-LIxt7WFf51NYr0BX44Wc {
  background: #464a4c;
  border-radius: 50%;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  margin-right: 0.75rem;
  max-height: 30px;
  max-width: 30px;
  min-height: 30px;
  min-width: 30px;
  line-height: 30px;
  text-align: center; }

._1-LIxt7WFf51NYr0BX44Wc._1OvXtcRw-8aJSzFcCi55Y5 {
  background: #18BC9C; }

._321GbELD9n-6Vv2iT-jFii {
  margin: 0; }

._321GbELD9n-6Vv2iT-jFii._1OvXtcRw-8aJSzFcCi55Y5 {
  color: #95a5a6; }
