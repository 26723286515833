.nearestBookings {
  overflow-y: hidden;
  height: calc(100% - 91px);
  margin-top: -1px; }
  .nearestBookings.card {
    border-radius: 0 0 0.25rem 0.25rem; }
  .nearestBookings.summary {
    padding: 1rem; }
  .nearestBookings ul.list-group {
    overflow-y: auto;
    display: block; }
  .nearestBookings .nearestBooking {
    display: flex; }
  .nearestBookings .bookingText-dim {
    color: #ddd; }
  .nearestBookings .separator {
    color: #636c72;
    font-size: 12px;
    padding-bottom: 0.25rem;
    padding-top: 0.25rem;
    text-align: center;
    text-transform: uppercase; }
    .nearestBookings .separator:first-child {
      border: none; }
    .nearestBookings .separator:hover {
      z-index: initial; }
  .nearestBookings .nearestBookingTime {
    margin-right: 1rem; }
  .nearestBookings .nearestBookingUser {
    margin-right: 1rem; }
  .nearestBookings .nearestBookingControls {
    cursor: pointer;
    margin-left: auto; }

small.count {
  color: #636c72; }
