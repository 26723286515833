.btn.navButton {
  background: #f5f9fa;
  border-color: transparent;
  color: #212529;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 0.85rem;
  text-transform: uppercase; }
  .btn.navButton svg {
    vertical-align: text-bottom; }
  .btn.navButton svg + span {
    margin-left: 5px; }
  @media (max-width: 575.98px) {
    .btn.navButton span {
      display: none; } }
  .btn.navButton.home {
    padding-left: 0.85rem;
    padding-right: calc(0.85rem - $separator-width); }
  .btn.navButton:hover, .btn.navButton:not(:disabled):not(.disabled).active {
    border-color: transparent;
    color: #212529; }
    .btn.navButton:hover.create, .btn.navButton:not(:disabled):not(.disabled).active.create {
      background-color: #62e2a2; }
    .btn.navButton:hover.activate, .btn.navButton:not(:disabled):not(.disabled).active.activate {
      background-color: #5bc3ff; }
    .btn.navButton:hover.return, .btn.navButton:not(:disabled):not(.disabled).active.return {
      background-color: #ffaa5c; }
  .btn.navButton:not(:disabled):not(.disabled):focus.create {
    box-shadow: 0 0 0 0.2rem rgba(98, 226, 162, 0.5); }
  .btn.navButton:not(:disabled):not(.disabled):focus.activate {
    box-shadow: 0 0 0 0.2rem rgba(91, 195, 255, 0.5); }
  .btn.navButton:not(:disabled):not(.disabled):focus.return {
    box-shadow: 0 0 0 0.2rem rgba(255, 170, 92, 0.5); }

.navButtonSeparator {
  width: 4px; }
