.modal-body._34Pt4-w2MN8YRglwi1Yb59 {
  padding: 0; }

._2Q3IXEhfkDxdSeHqvkiKP0:hover {
  cursor: pointer; }

._1TyDXSi4B03Z9I0llC7QBc {
  color: #ccc;
  padding: 1rem; }
  ._1TyDXSi4B03Z9I0llC7QBc h5 {
    text-align: center; }
