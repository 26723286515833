html, body, #app, .container-fluid {
  height: 100%; }

html, body {
  background: #f3f6f7; }

#app {
  background: #f3f6f7;
  height: 100%; }

/** layout **/
div#actionBar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  background: #23464e;
  background-size: 50px;
  line-height: 36px;
  padding: 12px;
  margin-bottom: 10px; }

#omniboxCol, #navCol {
  padding-top: 15px; }

div.actionPanel {
  min-height: 500px;
  padding-bottom: 250px;
  z-index: 500; }
  @media (min-width: 576px) {
    div.actionPanel {
      height: calc(100% - 76px); } }

div.notificationsPanel {
  position: absolute;
  top: 76px;
  left: 25%;
  width: 50%;
  z-index: 10; }

div.notificationsPanel div.alert {
  margin: 0 auto 10px;
  opacity: 0.95;
  text-align: center;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  transition: 0.25s;
  width: 100%; }

div.tooltip > .tooltip-inner {
  background-color: #23464e;
  padding: 10px 15px;
  max-width: 500px; }
  div.tooltip > .tooltip-inner.tooltip-right .tooltip-inner::before {
    border-right-color: #23464e; }

#manta-app div.resourceCategories {
  height: 100%; }

@media (max-width: 767.98px) {
  .resourceBrowser {
    display: none; } }
