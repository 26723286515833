._2DYqu1VyRshjaMvrk5dGhA {
  height: 100%;
  overflow: hidden;
  position: relative; }

._21kdwLKIsvJug9VW1cE__k {
  height: 450px;
  max-height: 60%; }

._2E6EPxeImvqCJXqeRlUK50 {
  height: 100%;
  overflow-y: auto; }
