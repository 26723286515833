._1cEKgF8v6OXcudZIoM8NKF {
  background-color: #f7f7f9;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  height: 300px;
  margin-bottom: 1rem;
  width: 100%; }

._3ZavnMCTy-Fn0iVfghhssF {
  border: 1px dashed #ced4da;
  border-radius: 0.25rem;
  padding: 1rem;
  text-align: center; }

._2p89BIGhvXIYs-kbEuWZgR {
  background: #eceeef; }

._1Y7mzi7RGnc3xrFUqARzmz {
  background: #eceeef;
  color: #464a4c; }
