@media (max-width: 575.98px) {
  .controlPanel {
    margin-bottom: 10px; } }

.controlPanel .controlPanelContainer {
  display: flex; }
  @media (max-width: 575.98px) {
    .controlPanel .controlPanelContainer {
      flex-direction: column; } }

.controlPanel > div > .panelStatusMessage {
  padding: 25px 0; }

.controlPanel #bookingTimesWidget .panelStatusMessage {
  border: 1px solid #f7f7f9;
  border-radius: 0.25rem;
  margin-top: 4px;
  padding: 2.5px 0 2.5px; }

.controlPanel #agreementWidget .agreementCheckbox {
  display: flex;
  align-items: center;
  margin-left: auto; }
  .controlPanel #agreementWidget .agreementCheckbox .form-check-input {
    position: relative;
    margin: 0; }

@media (min-width: 576px) {
  .controlPanel .options, .controlPanel .status {
    width: 40%; } }

.controlPanel .options .list-group {
  width: 100%; }

.controlPanel .options .list-group-item-action.open:hover {
  background: transparent; }

.controlPanel .options .collapse .list-group-item, .controlPanel .options .collapsing .list-group-item {
  padding-bottom: 0;
  padding-top: 0; }

.controlPanel .list-group-flush:first-child .userWidget.list-group-item:first-child {
  border-top-left-radius: 0.25rem; }

.controlPanel .list-group-flush:first-child .list-group > .list-group-item:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.125); }

.controlPanel .status {
  padding: 10px; }

.controlPanel .footer {
  padding: 0; }
  @media (max-width: 575.98px) {
    .controlPanel .footer {
      flex-direction: column; } }

@media (min-width: 576px) {
  .controlPanel .resources, .controlPanel .controls {
    border-left: 1px solid rgba(0, 0, 0, 0.125);
    width: 60%; } }

.controlPanel .resources ul.list-group {
  width: 100%; }

.controlPanel .controls, .controlPanel .status {
  padding: 10px; }

.controlPanel .creator {
  margin-left: 0.25rem; }

.controlPanel textarea, .controlPanel .times input {
  font-size: 10px;
  margin: 0.25rem auto 0; }

.controlPanel form, .controlPanel textarea {
  width: 100%; }

.controlPanel .times {
  display: flex;
  justify-content: space-between; }

.controlPanel .times .rdt {
  width: 48%; }

.controlPanel .times input {
  text-align: center;
  height: initial;
  padding: 0.25rem 0.5rem; }

.controlPanel .status {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px; }

.controlPanel .status svg.svgIcon {
  margin-right: 5px; }

.controlPanelWidget.list-group-item {
  background: #fdfdfd;
  font-size: 12px;
  display: flex;
  justify-content: start;
  width: 100%; }

.controlPanelWidget {
  transition: 0.25s; }
  .controlPanelWidget form {
    margin: 5px auto 0; }
  .controlPanelWidget textarea {
    font-size: 12px; }
  .controlPanelWidget > svg {
    margin-right: 10px; }
  .controlPanelWidget.open {
    border-bottom-color: transparent;
    transition: 0.25s; }

#bookingTimesWidget {
  display: block; }

.userWidget {
  border-radius: 0.25rem;
  background: #f7f7f9;
  padding: 7px 15px 7px 10px;
  width: 100%; }

.list-group-item.userWidget {
  display: flex;
  flex-wrap: nowrap; }
  .list-group-item.userWidget .picture {
    width: 55px; }
  .list-group-item.userWidget .picture img {
    border-radius: 5px;
    width: 45px; }
  .list-group-item.userWidget .badges {
    margin-bottom: 0; }
  .list-group-item.userWidget .badges span {
    display: inline-block;
    margin-bottom: 3px;
    margin-right: 3px;
    text-transform: capitalize; }

.bookingTimeControls {
  width: 100%; }

.bookingTimeControls input {
  font-size: 10px;
  margin: 0.25rem auto 0;
  padding: 0.25rem 0.5rem;
  height: initial;
  text-align: center; }

.bookingTimeControls .rdt {
  float: left;
  margin-left: 2%;
  width: 48%; }

.bookingTimeControls .rdt:first-child {
  margin-left: 0;
  margin-right: 2%; }

.Select-control {
  border-radius: 0 4px 4px 0; }

.Select {
  width: 100%; }

.bookingID {
  font-family: monospace; }

.list-group-flush:last-child .resourceWidget.list-group-item:last-child:first-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }

.resourceWidget {
  border-bottom: 1px solid #eee;
  /*margin-left: 2%;*/
  padding: 3px 0;
  /*width: 98%;*/ }

.resourceWidget:hover {
  cursor: default; }

.resourceWidget.inactive:hover {
  background: #fff; }

/*.resourceWidget:last-child
{
    border-bottom: none;
}*/
.resourceWidget.selected,
.resourceWidget.selected:hover,
.resourceWidget.selected:focus {
  background: #fffde4;
  /*font-weight: bold;*/ }

.resourceWidget .name {
  float: left;
  width: 70%;
  word-break: break-all; }

.resourceWidget .name span {
  font-weight: bold; }

.resourceWidget .tools {
  float: right;
  text-align: right;
  width: 30%; }

.resourceWidget .tools a {
  padding-right: 5px; }

.resourceWidget .tools a:hover {
  cursor: pointer;
  opacity: 0.85; }

.resourceWidget .tools a.disabled {
  opacity: 0.25; }

.resourceWidget .tools a.disabled:hover {
  cursor: default;
  opacity: 0.25; }

.removeResource path {
  fill: red; }

.renewResource path {
  fill: #5bc3ff; }

.activateResource path {
  fill: #5bc3ff; }

.returnResource path {
  fill: #ffaa5c; }

.bookingErrors {
  font-size: 12px;
  width: 100%; }

.bookingErrors li {
  padding: 5px 10px; }

.bookingErrors .list-group-flush:first-child .list-group-item:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.125); }

.resourceImageModalBody {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 450px; }
